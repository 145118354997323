export default class {
    constructor({
        rangeBar,
        rangePrice,
    }) {
        // Elements and class variables        
        const rangeBarWidth = document.querySelector(rangeBar).offsetWidth;
        const rangePriceWidth = document.querySelector(rangePrice).offsetWidth;

        if (rangePriceWidth > rangeBarWidth) {
            const offSet = (rangePriceWidth - rangeBarWidth) / 2;
            const rangePriceContainer = document.querySelector(rangePrice);
            rangePriceContainer.style.left = `-${offSet}px`;
        }
    }
}
